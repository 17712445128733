import React from 'react'
import EmailIcon from '../../svg/email-icon'
import { useTranslations } from '../../hooks/use-translations'

interface EmailLinkProps {
  email?: string
  onClick?: () => void
  className?: string
}

const EmailLink: React.FC<EmailLinkProps> = props => {
  const { t } = useTranslations()
  const email = props.email ?? t('info_email')

  return (
    <div
      className={props.className}
      style={{
        display: 'flex',
        alignItems: 'center',
        fontSize: 18,
        fontWeight: 500,
      }}
    >
      <EmailIcon style={{ marginRight: 8 }} />
      {props.onClick ? (
        <span className="has-text-white" onClick={props.onClick} title={email}>
          {email}
        </span>
      ) : (
        <a href={`mailto:${email}`} className="has-text-white" title={email}>
          <span itemProp="email">{email}</span>
        </a>
      )}
    </div>
  )
}

export default EmailLink
