import React from 'react'

const InstIcon: React.FC<React.SVGProps<SVGSVGElement>> = ({
  style,
  ...props
}) => (
  <svg
    width="54"
    height="54"
    viewBox="0 0 54 54"
    fill="none"
    color="#102538"
    xmlns="http://www.w3.org/2000/svg"
    style={{ fill: 'none', ...style }}
    {...props}
  >
    <rect width="54" height="54" rx="5" fill="white" />
    <path
      d="M21.5812 14C17.4006 14 14 17.4038 14 21.5854V32.4188C14 36.5994 17.4038 40 21.5854 40H32.4188C36.5994 40 40 36.5962 40 32.4146V21.5812C40 17.4006 36.5962 14 32.4146 14H21.5812ZM34.5833 18.3333C35.1813 18.3333 35.6667 18.8187 35.6667 19.4167C35.6667 20.0147 35.1813 20.5 34.5833 20.5C33.9853 20.5 33.5 20.0147 33.5 19.4167C33.5 18.8187 33.9853 18.3333 34.5833 18.3333ZM27 20.5C30.5848 20.5 33.5 23.4153 33.5 27C33.5 30.5848 30.5848 33.5 27 33.5C23.4153 33.5 20.5 30.5848 20.5 27C20.5 23.4153 23.4153 20.5 27 20.5ZM27 22.6667C25.8507 22.6667 24.7485 23.1232 23.9359 23.9359C23.1232 24.7485 22.6667 25.8507 22.6667 27C22.6667 28.1493 23.1232 29.2515 23.9359 30.0641C24.7485 30.8768 25.8507 31.3333 27 31.3333C28.1493 31.3333 29.2515 30.8768 30.0641 30.0641C30.8768 29.2515 31.3333 28.1493 31.3333 27C31.3333 25.8507 30.8768 24.7485 30.0641 23.9359C29.2515 23.1232 28.1493 22.6667 27 22.6667Z"
      fill="currentColor"
    />
  </svg>
)

export default InstIcon
