import React from 'react'

const TikTokIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg
    width="54"
    height="54"
    viewBox="0 0 54 54"
    fill="none"
    color="#102538"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="54" height="54" rx="5" fill="white" />
    <path
      d="M38.4545 13H15.5455C14.1391 13 13 14.1391 13 15.5455V38.4545C13 39.8609 14.1391 41 15.5455 41H38.4545C39.8609 41 41 39.8609 41 38.4545V15.5455C41 14.1391 39.8596 13 38.4545 13ZM36.786 25.0578C36.6205 25.0731 36.4538 25.0833 36.2858 25.0833C34.3831 25.0833 32.7107 24.1058 31.7371 22.6256C31.7371 26.5062 31.7371 30.9225 31.7371 30.9964C31.7371 34.4136 28.9664 37.1831 25.5504 37.1831C22.1344 37.1818 19.3636 34.4111 19.3636 30.9951C19.3636 27.5778 22.1344 24.8084 25.5504 24.8084C25.6802 24.8084 25.8062 24.8198 25.9322 24.8275V27.8769C25.8049 27.8616 25.6815 27.8387 25.5504 27.8387C23.8067 27.8387 22.3927 29.2527 22.3927 30.9964C22.3927 32.74 23.8055 34.154 25.5504 34.154C27.2953 34.154 28.8353 32.7795 28.8353 31.0358C28.8353 30.9658 28.8658 16.8195 28.8658 16.8195H31.7791C32.0527 19.4247 34.1565 21.4815 36.786 21.6698V25.0578Z"
      fill="currentColor"
    />
  </svg>
)

export default TikTokIcon
