import React from 'react'

const WhatsappBtnIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19.077 4.928C17.191 3.041 14.683 2.001 12.011 2C6.505 2 2.024 6.479 2.022 11.985C2.021 13.745 2.481 15.463 3.355 16.977L2 22L7.233 20.763C8.692 21.559 10.334 21.978 12.006 21.979H12.01C17.515 21.979 21.996 17.499 21.999 11.994C22.001 9.325 20.963 6.816 19.077 4.928ZM16.898 15.554C16.69 16.137 15.671 16.699 15.213 16.74C14.755 16.782 14.326 16.947 12.218 16.116C9.681 15.116 8.079 12.515 7.955 12.349C7.83 12.182 6.936 10.996 6.936 9.768C6.936 8.54 7.581 7.936 7.81 7.687C8.039 7.437 8.309 7.375 8.476 7.375C8.642 7.375 8.809 7.375 8.954 7.381C9.132 7.388 9.329 7.397 9.516 7.812C9.738 8.306 10.223 9.54 10.285 9.665C10.347 9.79 10.389 9.936 10.306 10.102C10.223 10.268 10.181 10.372 10.057 10.518C9.932 10.664 9.795 10.843 9.683 10.955C9.558 11.079 9.428 11.215 9.573 11.464C9.719 11.714 10.219 12.531 10.961 13.192C11.915 14.042 12.718 14.305 12.968 14.431C13.218 14.556 13.363 14.535 13.509 14.368C13.655 14.202 14.133 13.64 14.299 13.39C14.465 13.14 14.632 13.182 14.861 13.265C15.09 13.348 16.317 13.952 16.566 14.077C16.816 14.202 16.982 14.264 17.044 14.368C17.106 14.471 17.106 14.971 16.898 15.554Z"
      fill="currentColor"
    />
  </svg>
)

export default WhatsappBtnIcon
