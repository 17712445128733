import React from 'react'

const FBIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg
    width="54"
    height="54"
    viewBox="0 0 54 54"
    fill="none"
    color="#102538"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="54" height="54" rx="5" fill="white" />
    <path
      d="M27 13C19.2682 13 13 19.3004 13 27.072C13 34.1271 18.1705 39.9523 24.9076 40.9699V30.8017H21.4439V27.1027H24.9076V24.6413C24.9076 20.5662 26.8829 18.7772 30.2525 18.7772C31.8663 18.7772 32.7196 18.8974 33.1237 18.9524V22.1813H30.8252C29.3946 22.1813 28.8951 23.5444 28.8951 25.0808V27.1027H33.0875L32.5185 30.8017H28.8951V41C35.7284 40.0681 41 34.1956 41 27.072C41 19.3004 34.7318 13 27 13Z"
      fill="currentColor"
    />
  </svg>
)

export default FBIcon
