export enum PathEnum {
  Home = '/',
  Dashboard = '/dashboard/',
  Inspection = '/inspection/',
  Value = '/value/',
  Docs = '/docs/',
  Tools = '/tools/',
  News = '/news/',
  Bim = '/bim-services/',
  Digital = '/digital-services/',
  Solutions = '/solutions/',
  Articles = '/articles/',
  Contact = '/contact/',
  Brand = '/brand/',
  About = '/about/',
  Privacy = '/privacy/',
  Eula = '/eula/',
  Uniformat = '/uniformat/',
  Bim360Migrate = '/bim-360-migrate/',
}

export interface IPageArray extends Omit<IPage, 'pageArray'> {
  path: PathEnum | string
  target?: '_self' | '_blank' | '_parent' | '_top'
}

export interface IPage {
  title: string
  path?: PathEnum | string
  exact?: boolean
  pageArray?: IPageArray[]
}

export interface IFooterPage {
  title?: string
  pageArray: IPageArray[]
}

export interface IHeaderScreen {
  path: string
  imageId?: string
  videoData?: {
    posterId: string
    video: string
  }
  text?: string
  objectPosition?: string | number
  imageShadow?: boolean
}
