import React from 'react'

import { Button } from '../Common/Button'
import Typography from '../Common/Typography'
import { navigate } from 'gatsby'
import { useTranslations } from '../../hooks/use-translations'
import ArrowLinkButton from '../../svg/arrow-link-button'

import * as s from './SwitchfromAutodesktoSignalDocsBanner.module.scss'

const SwitchfromAutodesktoSignalDocsBanner = () => {
  const { getPath } = useTranslations()

  return (
    <>
      <div className={s.section}>
        <div className={s.content}>
          <div className={s.container}>
            <Typography className={s.text} variant="h4">
              Перейдите с BIM 360/АСС на SIGNAL DOCS —
              сделаем&nbsp;в&nbsp;кратчайшие&nbsp;сроки!
            </Typography>
            <Button
              className={s.btn}
              color="transparent"
              onClick={() => navigate(getPath('/bim-360-migrate/'))}
            >
              <span className={s.text_button}>Узнать подробнее</span>
              <ArrowLinkButton className={s.arrow} />
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}

export default SwitchfromAutodesktoSignalDocsBanner
