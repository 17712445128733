import React from 'react'

const LinkedinBtnIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    color="white"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.6667 20.6663H17.1052V14.6004C17.1052 12.9373 16.4733 12.0079 15.1569 12.0079C13.7249 12.0079 12.9767 12.975 12.9767 14.6004V20.6663H9.54449V9.11079H12.9767V10.6673C12.9767 10.6673 14.0087 8.75775 16.4609 8.75775C18.9119 8.75775 20.6667 10.2545 20.6667 13.3501V20.6663ZM5.44982 7.59768C4.28073 7.59768 3.33337 6.64289 3.33337 5.46534C3.33337 4.28779 4.28073 3.33301 5.44982 3.33301C6.61892 3.33301 7.56571 4.28779 7.56571 5.46534C7.56571 6.64289 6.61892 7.59768 5.44982 7.59768ZM3.67756 20.6663H7.25651V9.11079H3.67756V20.6663Z"
      fill="currentColor"
    />
  </svg>
)

export default LinkedinBtnIcon
