import React from 'react'

import HeaderScreen from '../HeaderScreen'
import MenuMobile from './MenuMobile'
import MenuDesktop from './MenuDesktop'

import { headerScreenData } from './config'
import { GlobalContext } from '../../context/GlobalContext'
import { AppRegionEnum } from '../../types/app-region.enum'

import * as s from './header.module.scss'
import SwitchfromAutodesktoSignalDocsBanner from '../SwitchfromAutodesktoSignalDocsBanner'
import { PathEnum } from '../../types/pages'
import cn from 'classnames'

const Header: React.FC = () => {
  const { region, location } = React.useContext(GlobalContext)
  const logoEl = React.useRef<SVGSVGElement>(null)
  const textEl = React.useRef<HTMLHeadingElement>(null)
  const headerData = headerScreenData[region as AppRegionEnum].find(
    data => data.path === location?.pathname
  )
  const isShowBanner =
    region === AppRegionEnum.Ru && location?.pathname !== PathEnum.Bim360Migrate

  React.useEffect(() => {
    if (logoEl.current && textEl.current) {
      logoEl.current.classList.add(s.header_animation_left)
      textEl.current.classList.add(s.header_animation_right)
    }
  }, [])

  return (
    <header className={cn(s.header, isShowBanner && s.header_banner)}>
      <div className={s.content}>
        <div className={s.container}>
          <MenuDesktop />
          <MenuMobile />
        </div>
        {isShowBanner && <SwitchfromAutodesktoSignalDocsBanner />}
      </div>

      {headerData && (
        <HeaderScreen data={headerData} logoRef={logoEl} textRef={textEl} />
      )}
    </header>
  )
}

export default Header
