import React from 'react'

import PhoneIcon from '../../svg/phone-icon'
import { useTranslations } from '../../hooks/use-translations'

interface PhoneLinkProps {
  phone?: string
  onClick?: () => void
  className?: string
}

const PhoneLink: React.FC<PhoneLinkProps> = props => {
  const { t } = useTranslations()
  const phone = props.phone ?? t('Phone')

  return (
    <div
      className={props.className}
      style={{
        display: 'flex',
        alignItems: 'center',
        fontSize: 18,
        fontWeight: 500,
      }}
    >
      <PhoneIcon style={{ marginRight: 8 }} />
      {props.onClick ? (
        <span onClick={props.onClick}>{phone}</span>
      ) : (
        <a
          href={`tel:${phone.replace(/[^+\d]/g, '')}`}
          className="has-text-white"
          title={phone}
        >
          <span itemProp="telephone">{phone}</span>
        </a>
      )}
    </div>
  )
}

export default PhoneLink
